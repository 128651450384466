
/* eslint-disable object-curly-newline */
const quotation = {
  productName: { id: 'Product', label: '', type: '' },
  plan: { id: 'properties.Plan', label: 'Plan (Coverage)', type: 'Select', collectionId: 'plan' },
  displayPremium: { id: 'price.main', label: 'Premium Amount', type: '' },
  premium: { id: 'properties.Price.Main Policy', label: '', type: '' },
  planName: { id: 'Selected Plan', label: 'Selected Plan', type: '' },
  gender: { id: '', label: 'Gender', type: 'GenderButton', collectionId: 'gender' },
  age: { id: 'properties.Age', label: '', type: 'Hidden' },
  dob: { id: 'properties.Main Applicant.DOB', label: 'Date of Birth', type: 'DatePicker', placeholder: 'DD / MM / YYYY' },
  paymentFrequency: { id: 'properties.Payment Frequency', label: 'Payment Frequency', type: 'RadioButton', collectionId: 'payment_frequency' },
  name: { id: 'properties.Main Applicant.Full Name', label: 'Full Name', type: 'Input', placeholder: 'Your full name as per NRIC' },
  nric: { id: 'properties.Main Applicant.NRIC', label: 'NRIC No.', type: 'Input', placeholder: 'Your NRIC No. without dashes' },
  religionName: { id: 'Religion Name', label: 'Religion', type: 'Hidden' },
  religion: { id: 'properties.Main Applicant.Religion', label: '', type: 'Select', collectionId: 'religion' },
  address: { id: 'Address', label: 'Address', type: 'Hidden' },
  address1: { id: 'properties.Main Applicant.Address1', label: 'Residential Address', type: 'Input', placeholder: 'Line 1' },
  address2: { id: 'properties.Main Applicant.Address2', label: '', type: 'Input', placeholder: 'Line 2 (Optional)' },
  city: { id: 'properties.Main Applicant.City', label: 'City/Town', type: 'Input', placeholder: 'City' },
  postcode: { id: 'properties.Main Applicant.Postcode', label: 'Postcode', type: 'Input', placeholder: 'Postcode' },
  stateName: { id: 'State Name', label: 'State/Province/Region', type: 'Hidden' },
  state: { id: 'properties.Main Applicant.State', label: '', type: 'Select', collectionId: 'state' },
  email: { id: 'properties.Main Applicant.Email Address', label: 'Email Address', type: 'Input', placeholder: 'Your email to receive policies & receipts' },
  mobile: { id: 'Mobile', label: 'Phone Number', type: '' },
  mobileBody: { id: 'properties.Main Applicant.Mobile No', label: '', type: 'Mobile', placeholder: '1234567890', collectionId: 'phone_code' },
  mobileCountryCode: { id: 'properties.Main Applicant.Mobile No Country Code', label: '', type: 'Hidden' },
  businessName: { id: 'Nature of Business Name', label: 'Nature of Business', type: 'Hidden' },
  business: { id: 'properties.Main Applicant.Nature of Business', label: '', type: 'BigSelect', collectionId: 'nature_business' },
  occupationName: { id: 'Occupation Name', label: 'Occupation', type: 'Hidden' },
  occupation: { id: 'properties.Main Applicant.Occupation', label: '', type: 'BigSelect', collectionId: 'occupation' },
  occupationClass: { id: 'properties.Main Applicant.OccupationClass', label: '', type: 'Hidden', collectionId: 'extendedProperties.class' },
  healthQuestion: { id: 'properties.Main Applicant.Health Question', label: 'Health Question', type: 'Input', placeholder: 'Health Question' },
  weight: { id: 'properties.Main Applicant.Weight', label: 'Weight (kg)', type: 'Input', placeholder: 'Weight' },
  height: { id: 'properties.Main Applicant.Height', label: 'Height (cm)', type: 'Input', placeholder: 'Height' },
  questionnaire: { id: 'properties.Questionnaire', label: '', type: 'Hidden' },
  presetPolicyCode: { id: 'properties.presetPolicyCode', label: '', type: '' },
  country: { id: 'Country', label: 'Country', type: 'Select' },
  marketingURL: { id: 'properties.Marketing Info.Full URL', label: 'URL', type: 'Hidden' },
  marketingSource: { id: 'properties.Marketing Info.Source', label: 'Source', type: 'Hidden' },
  marketingMedium: { id: 'properties.Marketing Info.Medium', label: 'Medium', type: 'Hidden' },
  marketingCampaign: { id: 'properties.Marketing Info.Campaign', label: 'Campaign', type: 'Hidden' },
  marketingContent: { id: 'properties.Marketing Info.Content', label: 'Content', type: 'Hidden' },
  marketingTerm: { id: 'properties.Marketing Info.Term', label: 'Term', type: 'Hidden' },
  marketingReferralCode: { id: 'properties.Marketing Info.Referral Code', label: 'Referral Code', type: 'Hidden' },
};

const policy = {
  ...quotation,
  paymentMode: { id: 'properties.Payment Response.PymtMethod', label: '', type: '' },
  paymentModeName: { id: 'PymtMethod Name', label: 'Mode of Payment', type: '' },
  paymentId: { id: 'properties.Payment Response.PaymentID', label: 'PaymentID', type: '' },
};

const baseFields = { quotation, policy };

export default baseFields;