import { ProductService } from '@xc-core/services';
import actionTypes from '@redux/actionTypes';
import { productLib } from '@xc-core/lib';
import QuotationScriptValidator from '@xc-core/validation/quotationScriptValidator';
import Notification from "react-notification-system-redux";
import {getNotificationMessage} from "../../_services";
import BaseAction from './base';

class ProductAction extends BaseAction {
  constructor() {
    super();
    this.service = new ProductService();
  }

  protected actions = actionTypes.product;

  protected service: ProductService;

  protected csvExportScope = '';

  protected editScope = '';

  protected createScope = '';

  public setList = () => async (dispatch: any) => {
    dispatch(this.createAction(this.actions.LIST_LOADING));
    try {
      let products = await this.service.getList();
      products = productLib.prepareList(products);
      dispatch(this.createAction<any>(this.actions.LIST_GET, products));
    } catch (error) {
      if (error.status === 401) {
        dispatch(this.createAction(this.actions.LIST_SET_FORBIDDEN));
      }
      // if (error.status === 404) {
      //   dispatch(this.createAction(this.actions.LIST_SET_NOT_FOUND));
      // }
      dispatch(this.createAction(this.actions.LIST_FAILED, error));
    }
  }

  public setQuotationValidationModuleInStorage = (id: number) => async (): Promise<void> => {
    let script = '';
    try {
      script = await this.service.getQuotationValidationModule(id);
      const quotationScriptValidator = new QuotationScriptValidator();
      quotationScriptValidator.storeAndAddScript(script);
    // eslint-disable-next-line no-empty
    } catch (error) {
    }
  }

  public getTemplateByProductCode = (type:'policy' | 'quotation',productCode: string) => async () => {
    try {
      const res =await this.service.getTemplateByProductCode(type,productCode);
      return res;
    } catch (error) {
      console.error('getTemplateByProductCode ', error);
      return '';
    }
  }

  public updateProductTemplateJSON= (type:'policy' | 'quotation', obj:IObject) => async (dispatch: any, getState: any) => {
    try {
      const res = await this.service.updateProductTemplateJSON(type,obj);
      dispatch(Notification.success(getNotificationMessage("Updated successfully.") as INotificationMessage));
      return res;
    } catch (error) {
      console.error('addProductTemplateJSON ', error);
      return '';
    }
  }
}

export default ProductAction;
