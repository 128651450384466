import config from '@xc-core/config';

class Request {
  constructor(obj?: object | null, method = 'GET') {
    this.method = method;
    this.body = obj ? JSON.stringify(obj) : null;
    this.options = {
      headers: this.getHeaders(),
      method,
      body: this.body,
    };
  }

  private getHeaders = () => new Headers({
    'content-type': 'application/json',
    'x-auth-token': config.token
  });

  public method: string;

  public body: string | null;

  public options: IObject;
}

export default Request;
