import React from 'react';
import { connect } from 'react-redux';
import {
  Form, FormGroup, Label, Input, Button,
} from 'reactstrap';
import Field from '@containers/getQuote/wizard/field';
import { policyActions } from '../actions';
import { ModalUi } from '../../../../_components';

class CancelForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      properties: {
        remarks: '',
        reason: '',
      },
      sendingRequest: false,
      cancelRemarkIsValid: true,
      cancelReasonIsValid: true,
    };
  }

  componentDidMount() {
    const { properties, hasAdditionalEmails = false } = this.props;
    let newState = {};
    if (hasAdditionalEmails) {
      newState = {
        'CC Email': properties['CC Email'],
        'BCC Email': properties['BCC Email'],
      };
    }
    this.setState((prevState) => ({
      properties: {
        ...prevState.properties,
        ...newState,
      },
    }));
  }

  handleInputChange = e => {
    this.setState({
      properties: { ...this.state.properties, [e.target.id]: e.target.value }
    });
  };

  handleSubmit = async() => {
    const {policy:{ id } ,callback} = this.props
    if(this.state.properties["remarks"] && (!this.props.cancellationReasons || this.state.properties["reason"])) {
      this.setState({sendingRequest: true, cancelRemarkIsValid: true});
      const res = await this.props.cancelPolicy(id, this.state.properties);
      if(callback) callback(res)
      this.setState({sendingRequest: false});
    } else {
      this.setState({
        cancelRemarkIsValid: (this.state.properties["remarks"] !== ""),
        cancelReasonIsValid: (this.state.properties["reason"] !== "")
      })
    }

  };
  toggleModal = () => {
    const {
      toggleModal, isOpen,
      properties: propsProperties,
      hasAdditionalEmails = false,
    } = this.props;
    const { properties } = this.state;
    this.setState({ cancelRemarkIsValid: true, cancelReasonIsValid: true });
    let emailsState = {};
    if (hasAdditionalEmails) {
      emailsState = {
        'CC Email': propsProperties['CC Email'],
        'BCC Email': propsProperties['BCC Email'],
      };
    }
    toggleModal();
    if (isOpen) {
      this.setState({
        properties: {
          ...properties,
          ...emailsState,
          reason: '',
          remarks: '',
        },
      });
    }
  }

  render() {
    const { isOpen, hasAdditionalEmails = false } = this.props;
    const { properties, sendingRequest: disabled, cancelRemarkIsValid, cancelReasonIsValid } = this.state;
    return (
      <ModalUi isOpen={isOpen} title="Remarks" toggle={this.toggleModal}>
        <Form>
          <FormGroup>
            {this.props.cancellationReasons &&
            <div>
              <Input
                type="select"
                name="reason"
                id="reason"
                value={properties["reason"]}
                onChange={this.handleInputChange.bind(this)}
              >
                <option disabled value="">Select Option</option>
                { this.props.cancellationReasons.map((reason, index) => {
                  return <option value={reason.code} key={index}>{reason.name}</option>
                })}
              </Input>
              {!cancelReasonIsValid ? <p className="text-danger">this field is required.</p> : <></>}
            </div>
            }
            
            <Label for="remarks">Please state your reasons for the certificate cancellation.</Label>
            <Input
              id="remarks"
              name="remarks"
              type="textarea"
              value={properties["remarks"]}
              onChange={this.handleInputChange.bind(this)}
              title="remarks"
              style={{ height: 130 }}
            />
            {!cancelRemarkIsValid ? <p className="text-danger">Reason for Cancellation is required.</p> : <></>}
          </FormGroup>
          {hasAdditionalEmails && (
            <div className='additional-tag-styles'>
              <p>Other Emails to Receive Policy</p>
              <FormGroup>
                <Label for='cc'>CC:</Label>
                <Field
                  model={{
                    id: 'CC Email',
                    type: 'MultiValueInput',
                    handler: (pair) => ({ target: pair }),
                    style: { col: '4' },
                    label: 'cc',
                  }}
                  item={properties}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </FormGroup>
              <FormGroup>
                <Label for='bcc'>BCC:</Label>
                <Field
                  model={{
                    id: 'BCC Email',
                    type: 'MultiValueInput',
                    handler: (pair) => ({ target: pair }),
                    style: { col: '4' },
                    label: 'bcc',
                  }}
                  item={properties}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </FormGroup>
            </div>
          )}
          <FormGroup className="text-right mb-0">
            <Button color="muted" className="mr-3 customLeastbtn" onClick={this.toggleModal} disabled={disabled}>Cancel</Button>
            <Button onClick={this.handleSubmit.bind(this)} className="customPrimarybtn" color="primary" disabled={disabled}>Submit</Button>
          </FormGroup>
        </Form>
      </ModalUi>
    )
  }
}

function mapStateToProps(state) {return {}}

function mapDispatchToProps(dispatch) {
  return {
    cancelPolicy: (policyId, properties) => dispatch(policyActions.cancelPolicy(policyId, properties))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelForm);
