//other server keys will be updated
let token = localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')).token : ''

//const url = 'https://1d734b42.ngrok.io';
const quotationUrl = 'https://staging2.coverplus.io/services/quote';
const policyUrl = 'https://staging2.coverplus.io/services/policy';
const productsUrl = 'https://staging2.coverplus.io/services/rate';
const auditUrl = 'https://test-demo-portal.coverplus.io/services/audit';
const url = 'https://staging2.coverplus.io/services/tds';
export const env = {
  apiURL: url, 
  apiVersion: `${url}`,
  quotationUrl: quotationUrl,
  policyUrl,
  productsUrl,  
  auditUrl,
  token
};

