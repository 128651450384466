import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { Details } from "../../../@containers/quotation";
import {objLib} from "../../../@xc-core/lib";
import AutoInsurance from "../../../models/policy/autoInsurance";
import { sidebarModel } from "models";
import { policyActions } from "Products/shared/policyListing/actions";
import { ProductActions } from '@redux/actions';
import { store } from "_helpers";
import CancelForm from "Products/shared/policyListing/components/CancelForm";
import RemarkModal from 'Products/shared/policyListing/components/EndorseForm';
import { utils } from 'utils';
import { QuotationScriptValidator } from '@xc-core/validation';
import EndorsementCheckPriceModal from "../../../@containers/SharedPage/LowerPremiumEndorsementModal";
import lib from "../../../utils/lib";
import {dateLib} from "../../../@xc-core/lib";
const productActions = new ProductActions();

interface IEndorsePermission{
  financialEndorsementPermitted?: boolean;
  nonFinancialEndorsementPermitted?: boolean;
};

const PolicyDetail = (props: any) => {
  const { policy,history} = props;
  const [form, setForm] = useState<IObject>();
  const [open, setOpen] = useState<boolean>(false);
  const [isCancelModalOpen, setOpenCancelModal] = useState<boolean>(false);
  const [endorseMode, setEndorseMode] = useState<boolean>(false);
  const [endorseRemarkIsValid, setEndorseRemarkIsValid] =
    useState<boolean>(true);
  const [sendingRequest, setSendingRequest] = useState<boolean>(false);
  const [errorMessages, setErrorMessage] = useState<any>([]);
  const [isFinancialEndorsement, setIsFinancialEndorsement] =
    useState<boolean>(false);
  const [paymentDetailForm, setPaymentDetailForm] = useState<any>();
  const [initialPaymentDetailForm, setInitialPaymentDetailForm] =
      useState<any>();
  const [cb_auto_model, setModelCollections] = useState<{id:string,value:string,name:string,code:string}[]>([]);
  const isBelongToCompany =
    policy?.properties?.["Main Insured"]?.["Belongs to Company"] === "Yes";
  const model = AutoInsurance(
    isBelongToCompany ? "Company" : "Individual"
  ).body;
  const endorsementPermissions:IEndorsePermission = useSelector<IStore>(
      (state) => state.policyManagement.endorsementPermissions
  ) as IEndorsePermission;
  const paymentFormRedux = useSelector<IStore>(
      (state) => state.policyManagement.paymentDetailForm
  );
  const endorsementCheckData:IObject= useSelector<IStore>((state)=>state.policyManagement.endorsementCheckData) as IObject;
  const showPriceUpdateModal:IObject= useSelector<IStore>((state)=>state.policyManagement.showPriceUpdateModal) as IObject;
  const collections = useSelector<IStore>(
      (state) => state.policyManagement.detailCollections
  );
  const [backendvalidator , setBackendValidator]= useState<any>()
  const [validator, setValidator]=useState<any>()
  const [isEndorseModalOpen , setOpenEndorseModal] = useState<boolean>(false)
  const yearValue = lib.propertyAgeHandler('properties.Property Age' ,form?.properties?.['Property Age']).value;
  const dispatch = useDispatch();

  useEffect(()=>{
    let initialLoad=true;
    if(initialLoad){
      validatorHandler();
      setPaymentDetailForm(paymentFormRedux)
      setInitialPaymentDetailForm(paymentFormRedux)
    }
    return ()=>{
      initialLoad= false
    }

  },[])

  useEffect(() => {
    if (policy && !form) {
      const properties = {...policy.properties};
      setForm({
        ...policy,
        properties: {
          ...policy.properties,
          beneficiaries: properties.beneficiaries && Object.keys(properties.beneficiaries).length !== 0 ? policy.properties.beneficiaries : null
        }
      });
      updateCollectionHandler(properties?.["Insured Property"]?.Make || '')
      setBackendValidator(new QuotationScriptValidator())
    }
  }, [policy]);

  const validatorHandler=()=>{
    if(policy){
      dispatch(productActions.setQuotationValidationModuleInStorage(policy.productCode))
    }
    setValidator(new SimpleReactValidator(utils.validations.cbValidationObject))
  }

  const showForbiddenMessage = (message?: string) => {
    return store.dispatch({
      type: "SHOW_FORBIDDEN_MESSAGE",
      payload: message,
    });
  };

  const checkPermissionHandler = (permissionType: string) => {
    return dispatch(policyActions.hasPermission(permissionType));
  };

  const checkPriceModalHandler =()=>{
    return dispatch(policyActions.togglePriceUpdateModal());
  }

  const endorseModeHandler = async () => {
    const { financialEndorsementPermitted, nonFinancialEndorsementPermitted } =
      endorsementPermissions as IEndorsePermission;
    if (!financialEndorsementPermitted && !nonFinancialEndorsementPermitted) {
      showForbiddenMessage();
      return;
    }
    if (endorseMode) {
      setForm(policy)
      setErrorMessage([]);
      setIsFinancialEndorsement(false);
    }
    setEndorseMode(!endorseMode);
  };

  const cancelModalOpenHandler = async () => {
    const isInEffect = policy.status === "In Effect";
    const isPermitted = await checkPermissionHandler(
      `Cancellation (${isInEffect ? "In-Effect" : "Not In Effect"} Certificate)`
    );
    if (!isPermitted) {
      showForbiddenMessage(
        `You are not allowed to cancel ${
          isInEffect ? "In-Effect" : "Not In Effect"
        } certificates.`
      );
      return;
    }
    setOpenCancelModal(!isCancelModalOpen);
  };

  const checkEndorsement = async (obj:{policy: any, isFinancialEndorsement: any})=>{
    if(form){
      return dispatch(
          policyActions.checkEndorsement(obj),
      )
    }
  }

  const isEndorsementAllowed = (endorsementType:string) => {
    if (!endorsementPermissions) return false;
    if (endorsementType === 'non-financial') {
      return endorsementPermissions.nonFinancialEndorsementPermitted;
    }
    return endorsementPermissions.financialEndorsementPermitted;
  };

  const updateCollectionHandler=(value:string)=>{
     setModelCollections((collections as IObject).cb_auto_make_model
        .find((el:any)=> el.name === value)
        ?.extendedProperties?.models?.map((el:string)=> {
          return {code:el ,id:el, name:el,value:el}
        }) || []);
  }

  const onChangeHandler=(pair:IPair,isPayment?:boolean)=>{
    let newForm:IObject = {...form}
    const {id , value} = pair;
    if(isPayment){
      setPaymentDetailForm({
        ...paymentDetailForm,
        properties:{
          ...paymentDetailForm.properties,
          [id]: value
        }
      })
    }
    if(id === 'properties.Coverage Type'){
      const isThirdPartyLiability = value === 'Third Party Liability';
      newForm = {
        ...newForm,
        properties:{
          ...newForm?.properties,
          'Property Age': isThirdPartyLiability ? 0 : null
        }
      }
    }

    if(id === 'properties.Start Date'){
      newForm ={
        ...newForm,
        properties:{
          ...newForm?.properties,
          'End Date': dateLib.addOneYearFromDate(value)
        }
      }
    }

    if(id === 'properties.Insured Property.Make'){
      newForm ={
        ...newForm,
        properties:{
          ...newForm?.properties,
          'Insured Property': {
            ...newForm?.properties?.['Insured Property'],
            Model:''
          }
        }
      }
      updateCollectionHandler(value)
    }
    setForm({...objLib.setValueWithLodash(newForm, id, value)})
  }

  const validateForm = async () => {
    // @ts-ignore
    let errorMessages = backendvalidator.validate({ properties: { ...form?.properties } }, '');
    setErrorMessage(errorMessages)
    return errorMessages
  };

  const endorsementCheckHandler = async () => {
    const validationResponse = await validateForm();
    let isFinancialEndorsement = false;
    if (validationResponse.length === 0 && form) {
      setSendingRequest(true)
      const formProperties = {...form.properties}
      const isThirdPartyLiability = formProperties['Coverage Type'] === "Third Party Liability";
      const financialField = ['Product Type','Sum Insured' , 'Coverage Type' ,'Cubic Capacity','Property Age','Start Date','End Date'];

      if(!financialField.every((el)=>form.properties[el] === policy.properties[el])){
        isFinancialEndorsement = true;
      }

      const response = await checkEndorsement({
        isFinancialEndorsement,
        policy: {
          refId: policy.refId,
          productSlug: policy.productSlug,
          properties:{
            'Main Insured':{...formProperties['Main Insured']},
            'Insured Property':{...formProperties['Insured Property']},
            'beneficiaries':{...formProperties['beneficiaries']},
            'Coverage Type':formProperties['Coverage Type'],
            'Cubic Capacity':formProperties['Cubic Capacity'],
            'Sum Insured': isThirdPartyLiability ? 0 :formProperties['Sum Insured'],
            'Property Age': isThirdPartyLiability ? 0 : formProperties['Property Age'],
            'Start Date': formProperties['Start Date'],
            'End Date': formProperties['End Date']
          }
        },
      });

      // @ts-ignore
      if (!response || response.type !== 'TOGGLE_PRICE_UPDATE_MODAL') {
        setOpenEndorseModal(true)
      }
      setSendingRequest(false)
    }
    setIsFinancialEndorsement(false)
  }

  const handleEndorseRemarkChange = (remarks:string) => {
    setForm({
      ...form,
      'Endorse Remarks': remarks,
    })
    setEndorseRemarkIsValid(true)
  }

  const endorseConfirmHandler = async () => {
    if(!form){
      return;
    }
    const endorseRemarks = form['Endorse Remarks'];
    if(!endorseRemarks){
      setEndorseRemarkIsValid(false)
      return;
    }
    setSendingRequest(true)
    const res:IObject = await dispatch(policyActions.endorseConfirm(endorsementCheckData?.endorsementRequestId, endorseRemarks));
    if(res && res.level === 'success'){
      setForm(res.item)
      setOpenEndorseModal(false)
      setEndorseMode(false)
      history.push(`/policies/${res.item.id}`)
    }
    setSendingRequest(false)
  }

  const handleSubmitPayment = async () => {
      await dispatch(policyActions.updatePolicy(policy.id, paymentDetailForm, true))
  }

  const updateCollections=()=>{
    return {
      ...collections as any,
      years_of_manufacture: lib.generateYear(2000,'cb_auto_years_of_manufacture'),
      cb_auto_model
    }
  }

  const checkPriceModalProceedHandler= async ()=>{
    if (showPriceUpdateModal) await checkPriceModalHandler();
    setOpenEndorseModal(true)
  }

  let premiumSidebar = {...sidebarModel.policy.autoInsurance};
  if(form?.properties?.['Coverage Type'] === "Third Party Liability"){
    premiumSidebar= lib.removeSidebarBodyItem(premiumSidebar,[
      {
        parentKey:'Other Details',
        removeItem: ['properties.Sum Insured', 'properties.Property Age']
      }
    ])
  }

  const checkPriceModel= lib.removeSidebarBodyItem(sidebarModel.policy.autoInsurance,[
    {
      parentKey:'Other Details',
      removeParent:true
    }
  ])


  return (
    <>
      <Details
        {...props}
        data={{
          ...form,
          properties:{
            ...form?.properties,
            'Property Age': yearValue === 0 ? new Date().getFullYear().toString() : yearValue
          }
        }}
        title={"Certificate Details"}
        type={"policy"}
        sidebarModel={premiumSidebar}
        components={[
          {
            title: `${isBelongToCompany ? "Company" : "Individual"} Details`,
            model: model.items.main,
            type: "primary",
          },
          {
            title: "Contact Details",
            model: model.items.contactDetails,
            type: "primary",
          },
          {
            title: "Property Details",
            model: model.items.insuredPropertyDetails,
            type: "primary",
          },
          {
            title: "Beneficiaries",
            model: model.items.beneficiaries,
            type: "secondary",
          },
        ]}
        open={open}
        toggle={() => setOpen(!open)}
        cancelPolicy={cancelModalOpenHandler}
        toggleEndorseMode={endorseModeHandler}
        endorseMode={endorseMode}
        endorsementPermissions={endorsementPermissions}
        isEndorsementAllowed={isEndorsementAllowed}
        collections={updateCollections()}
        onChange={onChangeHandler}
        errorMessage={errorMessages}
        endorsementCheck={endorsementCheckHandler}
        validator={validator}
        paymentForm={paymentDetailForm}
        submitPayment={handleSubmitPayment}
        sendingRequest={sendingRequest}
        productIcon={'icon-motorcar'}
        showRemarks={true}
      />
      <RemarkModal
          properties={form}
          isOpen={isEndorseModalOpen}
          toggleModal={()=>setOpenEndorseModal(false)}
          handleInputChange={(e:any) =>handleEndorseRemarkChange(e?.target?.value || '')}
          handleSubmit={()=>endorseConfirmHandler()}
          disableSubmit={sendingRequest}
          endorseRemarkIsValid={endorseRemarkIsValid}
      />
      <EndorsementCheckPriceModal
          isOpen={showPriceUpdateModal}
          toggleModal={checkPriceModalHandler}
          handleSubmit={checkPriceModalProceedHandler}
          endorsementCheckData={endorsementCheckData}
          previousPolicy={policy}
          sidebar={checkPriceModel}
      />
      <CancelForm
        isOpen={isCancelModalOpen}
        toggleModal={() => setOpenCancelModal(!isCancelModalOpen)}
        properties={form?.properties}
        policy={policy}
        callback={(res:any)=>{
          setForm(res.item)
          history.push(`/policies/${res.item.id}`)
          setOpenCancelModal(false)
        }}
      />
    </>
  );
};

export default PolicyDetail;
